import styled from 'styled-components';

export const ContactBox = styled.div`
  margin-top: 2rem;
  @media only screen and (min-width: 992px) {
    max-width: 60rem;
    margin: 2rem auto 0;
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 2rem;
  justify-content: center;
  align-items: ${props => props.align || 'center'};
`;

export const Label = styled.h3`
  text-align: left;
  font-size: 1.4rem;
  color: ${props => props.theme.txtColor};
  width: 100%;
`;

export const Input = styled.input`
  box-sizing: border-box;
  background-color: ${props => props.theme.bgColor};
  border: 0;
  border-radius: 0.8rem;
  margin: 0;
  padding: 1.4rem;
  width: 100%;
  outline-color: ${props => props.theme.primaryColor};
`;

export const TextArea = styled.textarea`
  box-sizing: border-box;
  background-color: ${props => props.theme.bgColor};
  border: 0;
  border-radius: 0.8rem;
  margin: 0;
  min-height: 8rem;
  padding: 2rem;
  width: 100%;
  outline-color: ${props => props.theme.primaryColor};
`;
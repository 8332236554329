import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Layout from '@layouts/index';
import SEO from '@layouts/seo';
import Seperator from '@components/seperator';
import ContactForm from '@components/contact-form/index';
import {
  Section,
  SectionButton,
  SectionTitle,
  SectionContent,
  SButtonsContainer,
  SBIcon,
  SBTitle,
} from '@components/styledComponents/index';
import webIcon from '@images/web-development.svg';
import appIcon from '@images/app-development.svg';

export default function Index() {
  const {
    site: {
      siteMetadata: { sections },
    },
  } = useStaticQuery(graphql`
    query HomeQuery {
      site {
        siteMetadata {
          sections {
            title
            desc
            target
            content
            alignContent
            buttons {
              id
              title
              icon
            }
            form
          }
        }
      }
    }
  `);

  return (
    <Layout alterFooter={true} isHome={true}>
      <SEO title="Home" />
      {sections.map(
        ({ title, desc, target, content, alignContent, buttons, form }, i) => (
          <Section key={`section-${target}`} id={target} alter={i % 2}>
            <Seperator alter={i % 2} />
            <SectionTitle dangerouslySetInnerHTML={{ __html: desc || title }} />
            {content && (
              <SectionContent
                align={alignContent}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}
            {buttons && (
              <SButtonsContainer>
                <Link to={`/${target}`}>
                  {buttons.map(({ id, title, icon }, index) => (
                    <SectionButton
                      key={`button-${id}`}
                      alter={index % 2}>
                      <SBTitle>{title}</SBTitle>
                      <SBIcon src={icon === 'webIcon' ? webIcon : appIcon} />
                    </SectionButton>
                  ))}
                </Link>
              </SButtonsContainer>
            )}
            {form && form === 'contact-form' && <ContactForm />}
          </Section>
        )
      )}
    </Layout>
  );
}

import React from 'react';
import { Button } from '@components/styledComponents/index';
import { ContactBox, Input, InputBox, Label, TextArea } from './style';

export default function ContactForm() {
  return (
    <ContactBox>
      <form
        method="GET"
        action="mailto:hello@belal.me"
        encType="multipart/form-data">
        <InputBox>
          <Label>Subject</Label>
          <Input type="text" name="subject" />
        </InputBox>
        <InputBox>
          <Label>Message</Label>
          <TextArea name="body" />
        </InputBox>
        <InputBox align="flex-start">
          <Button type="submit">Send</Button>
        </InputBox>
      </form>
    </ContactBox>
  );
}

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

const Container = styled.div`
  background-color: transparent;
  position: absolute;
  left: 0;
  right: 0;
  top: -10rem;
  @media only screen and (min-width: 992px) {
    top: -19rem;
    svg {
      height: 20rem;
    }
  }
`;

export default function Seperator (props) {
  const themeContext = useContext(ThemeContext);

  return (
    <Container>
      <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='105' viewBox='0 0 100 105' preserveAspectRatio='none'>
        <polygon points={props.alter ? '0,0 100,100 0,100' : '-1,100 100,0 100,100'} fill={props.alter ? themeContext.secondaryBgColor : themeContext.bgColor}/>
        <rect x="0" y="99" width="100" height="5" fill={props.alter ? themeContext.secondaryBgColor : themeContext.bgColor}></rect>
      </svg>
    </Container>
  );
}